import React from 'react'
import { Container } from 'react-bootstrap'

function ComingSoon() {
    return (
        <div className="body-container-wrapper">
            <Container>
                <div className="comingsoonwrapper">
                    <h1>Coming Soon...</h1>
                </div>
            </Container>

        </div>
    )
}

export default ComingSoon
