import React from 'react'
import { Link } from 'react-router-dom'
const TermsOfUse = () => {
    return (
        <div class=" bodypadding ">
            <div class=" container fontSz privacypolicy">


                <div class="inner_wrap ">
                    <h4 class="inner-container alignment"><u>BitAnimate, Inc.</u><br /></h4>
                    <h4 class="inner-container alignment2"><u>NapDetect</u><br /></h4>
                    <h4 class="inner-container alignment3"><u>Terms of Use</u></h4>

                    <div classname="">
                        <p class="heading"><b>1. <u>Overview</u></b></p>
                        <p>These Terms of Use constitute a legal agreement between you and BitAnimate, Inc. (“BitAnimate”, “we”,
                        “our”, or “us”) stating the terms and conditions that govern your use of the mobile application provided
                        by BitAnimate called NapDetect (the “Application”). Please read the Terms of Use carefully. By
                        downloading, installing, and/or using the Application, you agree to be bound by and comply with the
                        Terms of Use. You hereby represent and warrant that you are legally able to form a binding contract with
                        BitAnimate. If you do not agree to these terms and conditions, do not download, install and/or use the
                Application.</p>

                        <p><i><b > BitAnimate reserves the right to make changes to the Application and to
                        the Terms of Use at any time.</b></i>All changes are effective immediately when posted. Your
                continued use of the Application following the posting of the revised Terms of Use means that you accept
                and agree to the changes.</p>

                        <p>All Information BitAnimate collects is subject to our Privacy Notice posted <Link target="_blank" to="/privacypolicy">here</Link>.
                By using the
                Application, you consent to all actions taken by us with respect to your Information in compliance with
                the Privacy Notice. The Privacy Notice is incorporated into and governed by these Terms of Use. To the
                extent there is a conflict, the Terms of Use supersede the Privacy Notice. Terms like “Information” that
                are in these Terms of Use but not defined here are defined in the Privacy Notice.</p>

                        <p class="heading"><b>2.<u> Intellectual Property</u></b></p>
                        <p>The Application and its entire contents, data, features and functionality (including but not limited to
                        text, graphics, video, logos, button icons, databases and images) (“Application Content”) are the
                        property of BitAnimate or its licensors and are protected by copyright, trademark and other intellectual
                property laws, except as indicated below.</p>
                        <p>The BitAnimate name and related logos are trademarks and service marks (“Marks”) of BitAnimate.
                        BitAnimate's Marks may not be used without advance written permission of BitAnimate, including in
                        connection with any product or service that is not BitAnimate's, in any manner that is likely to cause
                        confusion, or in any manner that disparages, discredits, or misrepresents BitAnimate. Other products or
                        company names mentioned on the Application may be trademarks or service marks of their respective
                owners.</p>

                        <p>If you believe that any content on the Application violates your intellectual property rights, please
                        notify BitAnimate as described in Section 22.
            </p>

                        <p class="heading"><b>3. <u> Limited License And Prohibited Uses</u></b></p>
                        <p>BitAnimate grants you a personal, non-assignable, and non-exclusive license to access and use the
                        Application and Application Content only as an informative resource while using the Application. Any
                        other use, including the reproduction, modification, distribution, transmission, republication, framing,
                        display or performance of the Application or Application Content without prior permission of BitAnimate
                        is strictly prohibited. You may not download, print, copy, distribute, lease, or otherwise use the
                        Application or Application Content for commercial purposes, including publication, sale, or personal
                        gain. You may not remove any Mark or other proprietary notices, including, without limitation,
                        attribution information, credits, and copyright notices that have been placed on or near the Application
                or Application Content.</p>
                        <p>You may use the Application only for lawful purposes and in accordance with these Terms of Use. You agree
                that you will not:</p>

                        <p class="alignment4">
                        </p><ul>
                            <li>Use the Application in any way that violates any applicable federal, state, local or international
                            law or regulation.
                </li>

                            <li> Use the Application for the purpose of exploiting, harming or attempting to
                            exploit or harm
                            anyone
                            in any way.
                </li>
                            <li>Send, knowingly receive, upload, download, use or re-use any material which does not comply with the
                            Terms of Use.
                </li>
                            <li> Transmit, or procure the sending of, any advertising or promotional material, including any "junk
                            mail", "chain letter" or "spam" or any other similar solicitation, unless you have received
                            authorization from BitAnimate to advertise through the Application. If you would like to advertise
                    with BitAnimate, please contact us <a target="_blank" href="https://bitanimate.com/contact">here</a>.
                </li>
                            <li> Impersonate or attempt to impersonate BitAnimate, a BitAnimate employee, another user or any other
                            person or entity (including, without limitation, by using e-mail addresses associated with any of
                            the foregoing).
                </li>
                            <li> Engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the
                            Application, or which, as determined by BitAnimate, may harm BitAnimate or users of the Application
                            or expose them to liability.
                </li>
                            <li>Use the Application in any manner that could disable, overburden, damage, or impair the Application
                            or interfere with any other party's use of the Application, including their ability to engage in
                            real time activities through the Application.
                </li>
                            <li>Use any robot, spider or other automatic device, process or means to access the Application for any
                            purpose, including monitoring or copying any of the material on the Application.
                </li>
                            <li> Use any manual process to monitor or copy any of the material on the Application or for any other
                            unauthorized purpose without BitAnimate’s prior written consent.
                </li>
                            <li> Use any device, software or routine that interferes with the proper working of the Application.
                </li>
                            <li> Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or
                            technologically harmful.
                </li>
                            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the
                            Application, the server on which the Application is stored, or any server, computer or database
                            connected to the Application.
                </li>
                            <li> Attack the Application via a denial-of-Application attack or a distributed
                            denial-of-Application
                            attack.
                </li>
                            <li> Otherwise attempt to interfere with the proper working of the Application.</li>
                        </ul>
                        <p></p>

                        <p>DRIVERS ARE STRICTLY FORBIDDEN FROM INTERACTING WITH THE APPLICATION FOR ANY PURPOSE WHILE DRIVING.
                        INTERACTIONS WITH THE APPLICATION MAY ONLY TAKE PLACE AFTER YOU HAVE STOPPED YOUR VEHICLE IN AN
                        APPROPRIATE LOCATION PERMITTED BY LAW. ALTERNATIVELY, SUCH INTERACTIONS MAY BE HANDLED BY A PASSENGER
                        OTHER THAN THE DRIVER, PROVIDED IT DOES NOT INTERFERE WITH DRIVING AND DOES NOT DISTRACT THE DRIVER’S
                ATTENTION.</p>

                        <p class="heading"><b>4. <u> Account Registration</u></b></p>
                        <p>To access portions of the Application, you may be asked to provide registration details or other
                        Information. It is a condition of your use of the Application that all Information you provide is
                        complete, current, and accurate. All Information you provide to register with the Application or
                        otherwise is governed by our Privacy Notice, and you consent to all actions BitAnimate takes with
                respect to your Information consistent with our Privacy Notice.</p>

                        <p class="heading"><b>5. <u> Advertising Policies </u></b></p>
                        <p>The Application may contain advertisements. In consideration for BitAnimate letting you access and use
                        the Application, you agree that we, our affiliates, and our third-party partners may place advertising
                on the Application. </p>
                        <p class="heading"><b>6. <u>Use and Protection of Login Credentials </u></b></p>
                        <p>You are responsible for maintaining the confidentiality of your user name and password (“Login
                        Credentials”), if applicable. You are responsible for all uses of your account and Login Credentials,
                        whether or not authorized by you. You agree to notify BitAnimate immediately of any unauthorized access
                        to or use of your account or Login Credentials or any other breach of security. BitAnimate reserves the
                        right to disable your Login Credentials at any time in its sole discretion for any or no reason,
                        including if, in BitAnimate’s opinion, you have violated any provision of these Terms of Use.
            </p>


                        <p class="heading"><b>7. <u>Disclaimer And Limitation Of Liability</u></b></p>
                        <p>WHILE BITANIMATE ATTEMPTS TO PRESENT ACCURATE INFORMATION ON THE APPLICATION, THIS APPLICATION IS
                        PROVIDED ON AN "AS-IS" BASIS. BITANIMATE MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                        IMPLIED, AS TO THE OPERATION OR CONTENT OF THIS APPLICATION OR ANY OTHER SITE TO WHICH IT IS LINKED. TO
                        THE EXTENT PERMITTED BY LAW, BITANIMATE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
                        LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND INTELLECTUAL
                        PROPERTY INFRINGEMENT. BITANIMATE’S DIRECTORS, AND ITS EMPLOYEES WILL NOT BE LIABLE FOR ANY DAMAGES OF
                        ANY KIND ARISING FROM THE USE OF THIS APPLICATION OR ANY SITE FOR WHICH IT PROVIDES LINKS, INCLUDING BUT
                        NOT LIMITED TO DIRECT, INDIRECT, PUNITIVE AND CONSEQUENTIAL DAMAGES. YOUR SOLE REMEDY UNDER THESE TERMS
                        OF USE IS TO STOP USING THE APPLICATION. FROM TIME TO TIME, BITANIMATE MAY RESTRICT YOUR ACCESS TO SOME
                        PARTS OF THE APPLICATION, OR THE ENTIRE APPLICATION, FOR ANY REASON. BITANIMATE WILL NOT BE LIABLE FOR
                ANY REASON IF ALL OR ANY PART OF THE APPLICATION IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD.</p>


                        <p> YOU AGREE AND ACKNOWLEDGE THAT YOU ASSUME FULL, EXCLUSIVE, AND SOLE RESPONSIBILITY FOR THE USE OF AND
                        RELIANCE ON THE APPLICATION AND THAT YOUR USE OF OR RELIANCE ON THE APPLICATION IS MADE ENTIRELY AT YOUR
                        OWN RISK. BITANIMATE DOES NOT WARRANT THAT THE APPLICATION WILL OPERATE IN AN UNINTERRUPTED OR
                        ERROR-FREE MANNER, THAT DEFECTS WILL BE CORRECTED, THAT ANY OTHER SITE OR THE SERVER THAT MAKES IT
                        AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT IT IS A SAFE SUBSTITUTION FOR YOUR OWN
                        JUDGMENT. IF YOUR USE OF THIS APPLICATION RESULTS IN THE NEED FOR SERVICING OR REPLACING PROPERTY,
                MATERIAL, EQUIPMENT OR DATA, BITANIMATE IS NOT RESPONSIBLE FOR THOSE COSTS.</p>
                        <p>YOU AGREE AND ACKNOWLEDGE THAT IT IS YOUR SOLE RESPONSIBILITY TO DRIVE SAFELY IN ACCORDANCE WITH ALL
                        APPLICABLE TRAFFIC AND SAFETY LAWS WHILE USING THE APPLICATION, AND THAT BITANIMATE WILL NOT BE LIABLE
                        FOR ANY FORM OF LIABILITY ARISING FROM YOUR RELIANCE ON, OR IN CONNECTION WITH, THE USE OF THE
                        APPLICATION.
            </p>
                        <p class="heading"><b>8. <u>Apple and Google’s Liability; Third Party Beneficiary Rights</u></b></p>
                        <p>This is an agreement between you and BitAnimate, and not with Apple, Inc. (“Apple”), or Google, Inc.
                        (“Google”), or others. Neither Apple nor Google will be responsible for any claims by you or any third
                        party relating to your possession and/or use of the Application, including but not limited to (i)
                        product-liability claims, (ii) any claims that the Application fails to conform to any applicable legal
                        or regulatory requirement, (iii) claims arising under consumer-protection laws or similar legislation,
                        and (iv) claims by any third party that the Application or your possession and use of the Application
                        infringes the intellectual property rights of the third party. You agree that Apple, Google, and their
                        respective subsidiaries are third-party beneficiaries to this Terms of Use, and that upon your
                        acceptance of the Terms of Use, Apple and/or Google will have the right (and will be deemed to have
                accepted the right) to enforce these Terms of Use against you as a third party beneficiary thereof.</p>


                        <p class="heading"><b>9. <u> Third Party Content</u></b></p>
                        <p>This Application may include content provided by third parties. All statements and opinions expressed by
                        third parties are solely the opinions and the responsibility of the person or entity providing those
                        materials. Those materials do not necessarily reflect the opinion of BitAnimate. BitAnimate is not
                responsible for the content or accuracy of any materials provided by any third parties.</p>
                        <p class="heading"><b>10. <u>Advertising Content and Links To Other Web Sites</u></b></p>
                        <p>BitAnimate may present advertisement for third party websites or provide links to external web sites for
                        the convenience of Application users. The inclusion of an advertisement or external link on the
                        Application does not constitute or imply support or endorsement of any kind. BitAnimate does not control
                        those advertisements or web sites, is not responsible for their content or function, and is not
                        responsible for any loss or damage that may arise from your use of them. If you decide to view the
                        advertisements or access the third party sites linked to this Application, you do so entirely at your
                own risk and subject to the terms and conditions of use for such sites.</p>
                        <p class="heading"><b>11. <u>Wireless Access Charges </u></b></p>
                        <p>Certain Application functions may require data access, and the provider of data access for your device
                        may charge you data access fees in connection with your use of the Application. You are solely
                responsible for any data access or other charges you incur.</p>
                        <p class="heading"><b>12. <u>Uninstalling the Application</u></b></p>
                        <p>Uninstallation methods may vary depending on your device. To uninstall this Application, please use the
                application manager provided with your device or consult your device manual for reference.</p>
                        <p></p>

                        <p class="heading"><b>13. <u>Change or Update to Application; Termination</u></b></p>
                        <p>BitAnimate shall have the right for any reason, in its sole discretion, to terminate, change, suspend or
                        discontinue, temporarily or permanently, any aspect of the Application, including but not limited to
                        content or features, without notice to you. We may also impose limits on certain features and services
                        or restrict your access to parts or all of the Application with or without notice or liability. From
                        time to time, BitAnimate may make available updates or upgrades to the Application via software download
                        or other means. Such download may occur automatically without the need for an act on your part, or it
                        may require you to manually download an update or upgrade through the same source from which the
                        Application was originally downloaded. Certain functions of the Application may be modified or
                        discontinued as a result of any such update or upgrade, or may not be available if you have not
                downloaded all updates and upgrades made available by BitAnimate or otherwise. </p>
                        <p>The Application may contain information about BitAnimate or other products or services. BitAnimate makes
                        reasonable efforts to assure that the information in the Application is accurate as of the date the
                        Application is made available for download to you. Such information about BitAnimate or other products
                        or services may be updated from time to time, including without limitation, when the Application may be
                        updated or upgraded. You should periodically check whether an updated or upgraded version of the
                Application is available for download.</p>
                        <p class="heading"><b>14. <u>Indemnification</u></b></p>
                        <p>You agree to defend, indemnify and hold harmless BitAnimate, its affiliates, licensors and service
                        providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
                        suppliers, successors and assigns from and against any liabilities, damages, judgments, awards, losses,
                        costs, third party claims, injuries to persons or property, expenses and fees (including reasonable
                        attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the
                        Application, including, but not limited to, your content, any use of the Application Content, and
                services and products other than as expressly authorized in these Terms of Use. </p>
                        <p class="heading">
                            <b>15. <u>Payment, Fees, and Other Charges</u></b></p>
                        <p>You are solely responsible for all fees associated with your use of the application, including paid
                        plans, advertising, and those listed within the Application and the Application Content itself. You are
                        solely responsible for any and all duties, taxes, levies, or fees (including sales, use, or withholding
                taxes) imposed by any authority on you by virtue of your transactions through the Application.</p>

                        <p class="heading">
                            <b>16. <u> Subscription, Free Trial, Billing, and Cancellation</u></b></p>
                        <p><b class="">A. Subscription.</b> When you download or install the Application, you will have
                access to content and services
                provided to you without any fees, charges, or subscriptions (“Free Content”). The Application also
                contains content and services that are only provided via a paid subscription to the Application
                (“Premium Content”). By subscribing, downloading, or installing the Premium Content, you agree to
                subscribe to the Application for the term specified in your plan (“Subscription Plan”). All
                subscriptions are renewed automatically on the renewal date of your Subscription Plan. Upon renewal of
                your subscription, you will be billed the subscription fee unless you cancel before the last day of your
                current billing period. You may not be notified in advance of each renewal. </p>

                        <p><b class="">B. In-app Purchases.</b> We may include certain content that can be
                viewed without a subscription, but we require a separate one-time payment for unlimited viewing of such
                content. All transactions are final. Pricing and availability of such content is subject to change
                without notice. We reserve the right to remove such content at any time without issuing a refund.</p>

                        <p><b class="">C. Recurring Billing.</b> By subscribing to the Application, you
                authorize payment of the subscription fee at the then current rate and any additional charges you may
                incur
                in connection with your use of the Application. You acknowledge that the amount billed each billing
                period
                may vary for reasons including promotional offers, gift card redemptions, or added subscriptions. You
                authorize payment of such varying amounts, which may be billed in one or more charges during the billing
                cycle.</p>


                        <p><b class="">D. Billing Cycle.</b> You will be billed at the start of the paying
                period of your subscription and every paying period thereafter, depending on your Subscription Plan,
                unless you cancel your subscription or we terminate it. You must cancel your subscription before it
                renews for the next paying period of your billing cycle in order to avoid billing of the next cycle’s
                subscription fee. Subscription fees are fully earned upon payment. We reserve the right to change the
                timing of our billing or billing cycle.</p>

                        <p><b class="">E. No Refunds.</b> PAYMENTS ARE NONREFUNDABLE AND NO REFUNDS OR CREDITS
                WILL BE GIVEN FOR PARTIAL OR INCOMPLETE USE. We reserve the right, in our sole discretion, to provide
                refunds, discounts, or credits to subscribers on one or more instances without obligation to do so in
                the future. If any or all of the features of the Application are temporarily unavailable, you will not
                receive a refund.</p>

                        <p><b class="">F. Cancellation.</b> You may cancel your subscription at any time by
                logging into your account and following the instructions for cancellation. Cancellation will become
                effective at the end of your current billing period. You will not receive a refund or credit for your
                current billing period. After cancellation, you may continue to access the Application for the remainder
                of your current billing period.</p>


                        <p class="heading"><b>17. <u>Limitation On Time To File Claims</u></b></p>
                        <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE
                        MUST BE COMMENCED WITHIN THREE (3) MONTHS AFTER THE EVENT GIVING RISE TO THE ACTION OR CLAIM OCCURRED,
                        REGARDLESS OF WHEN YOU KNEW OR SHOULD HAVE KNOWN ABOUT IT; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                PERMANENTLY BARRED.</p>

                        <p class="heading"><b>18. <u>Injunctive Relief</u></b></p>
                        <p>You agree that a breach of these Terms of Use will cause irreparable injury to BitAnimate for which
                        monetary damages would not be an adequate remedy and BitAnimate shall be entitled to seek equitable
                        relief, in addition to any remedies it may have hereunder or at law, without having to post a bond or
                other security or prove damages.</p>

                        <p class="heading"><b>19. <u>Waiver And Severability</u></b></p>
                        <p>No waiver by BitAnimate of a term or condition set forth in these Terms of Use shall be deemed a
                        continuing waiver of such term or condition or a waiver of any other term or condition. Any failure of
                        BitAnimate to assert a right or provision under these Terms of Use shall not constitute a waiver of such
                        right or provision.
                        If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to
                        be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to
                        the minimum extent such that the remaining provisions of the Terms of Use will continue in full force
                and effect.</p>
                        <p class="heading"><b>20. <u> Entire Agreement</u></b></p>
                        <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and
                        BitAnimate with respect to the Application and supersede all prior and contemporaneous understandings,
                agreements, representations and warranties, both written and oral, with respect to the Application.</p>
                        <p class="heading"><b>21. <u>Applicable Law, Binding Arbitration, and Class Action Waiver</u></b></p>
                        <p>
                        </p><p> PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU TO AGREE TO RESOLVE DISPUTES
                BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION.</p>

                        <p> The laws of the State of Oregon will govern these Terms of Use without giving effect to any principles
                of conflicts of laws.</p>
                        <p> Any controversy or claim arising out of or relating to this agreement, or breach thereof, shall be
                        settled by arbitration administered by the American Arbitration Association in accordance with its
                        Commercial Arbitration Rules, then in effect; provided, however, that BitAnimate may seek injunctive
                        relief to enforce its or its affiliates’ intellectual property rights in any court of competent
                        jurisdiction. This arbitration provision is governed by the Federal Arbitration Act. The arbitration
                proceedings shall be held in Portland, Oregon. </p>
                        <p>All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on
                        an individual basis and not on a class basis. Claims of more than one customer or user cannot be
                arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>

                        <p class="heading" s=""><b>22. <u>Communications and Contact Information</u></b></p>
                        <p>BitAnimate may contact you regarding these Terms of Use or the Privacy Notice using any Information you
                        provide, or by any other means if you do not provide contact Information. If you no longer wish to
                        receive communications from BitAnimate, you can click on the “unsubscribe link” provided in such
                        communications or contact us <a target="_blank" href="https://bitanimate.com/contact">here</a>. BitAnimate may contact you regarding these Terms of Use or the Privacy
                        Notice using any Information you provide, or by any other means if you do not provide contact
                        Information. If you no longer wish to receive communications from BitAnimate, you can click on the
                “unsubscribe link” provided in such communications or contact us <a target="_blank" href="https://bitanimate.com/contact">here</a>.
            </p>
                        <p>For all other feedback, comments, requests for technical support and other communications relating to the
                Application, these Terms of Use, and the Privacy Notice, please contact us <a target="_blank" href="https://bitanimate.com/contact">here</a>{" "}
                 or by mail at:</p>
                        <p>
                            BitAnimate, Inc.<br />
                4800 Meadows Road, Suite 300 <br />
                Lake Oswego, Oregon 97035</p>
                        <p>EFFECTIVE DATE: May 27, 2020</p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default TermsOfUse



