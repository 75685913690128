import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from './components/home/Home';
import Header from './components/global/Header'
import Footer from './components/global/Footer'
import Page404 from './components/404/404Page';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/global/termsOfUse/TermsOfUse';
import ComingSoon from './components/comingSoon/ComingSoon';

const Routes = () => {
  return (
    <>
      <Router>
        <Header />
        <div className="body-container-wrapper">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/termsofuse" component={TermsOfUse} />
            <Route exact path="/comingsoon" component={ComingSoon} />
            <Route exact path="/404" component={Page404} />
            <Redirect from='*' to='/404' />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  )
}

export default Routes;