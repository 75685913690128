import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const PhoneScreenSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <Slider {...settings} className="homeslider">
            <div className="slidebox" >
                <div className="slidebox-inner">
                    <img src={require('../../assets/img/slider/logoslide.png')} alt="" />
                </div>
            </div>
            <div className="slidebox"  >
                <div className="slidebox-inner">
                    <img src={require('../../assets/img/slider/mobile1slide.png')} alt="" />
                </div>
            </div>
            <div className="slidebox"  >
                <div className="slidebox-inner">
                    <img src={require('../../assets/img/slider/mobile2slide.png')} alt="" />
                </div>
            </div>
            <div className="slidebox" >
                <div className="slidebox-inner middle_screen">
                    <img src={require('../../assets/img/slider/mobile3slide.png')} alt="" />
                </div>
            </div>
            <div className="slidebox" >
                <div className="slidebox-inner">
                    <img src={require('../../assets/img/slider/mobile4slide.png')} alt="" />
                </div>
            </div>
        </Slider>

    )
}

export default PhoneScreenSlider;