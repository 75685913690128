import React from 'react'

const CardWithIcon = [
    // {
    //     img: require("../../assets/img/svg/createac.svg"),
    //     heading : 'Create Your Account',
    //     content : <p>Download and Signup today for a free trial! <a href="#">Download Now</a></p>
    // },
    {
        img: require("../../assets/img/svg/aiv4.png"),
        heading : 'AI-Powered',
        content : 'NapDetect’s AI algorithms continuously analyze the person’s facial movements and head position by processing images from the front-facing camera of the device in real-time.'
    },
    {
        img: require("../../assets/img/svg/magnifiedclose.svg"),
        heading : <h3><span className="font-f text-dark">NapDetect</span> training</h3>,
        content : 'Each person has unique facial features. NapDetect Training will enable the AI algorithms to better analyze and detect whether the person is distracted, drowsy, or falling asleep.'
    },
    {
        img: require("../../assets/img/svg/safty.svg"),
        heading : 'Safety',
        content : 'Distracted and drowsy driving are among the top causes of motor vehicle accidents. NapDetect’s AI-powered, real-time safety solution can help with keeping drivers alert and improving road safety.'
    }

]

export default CardWithIcon;