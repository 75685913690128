import React, { useEffect } from "react";
import { Card, CardDeck } from "react-bootstrap";
import CardWithIcon from "../../assets/data/Data";
import { BannerSliderV2 } from "../../assets/data/BannerSlider";
import PhoneScreenSlider from "../../assets/data/PhoneScreenSlider";
import { Player } from "video-react";

const Home = () => {
  return (
    <>
      {/* <BannerSlider /> */}
      <BannerSliderV2 />
      <div className="page_container">
        <div className="card_section">
          <CardDeck>
            <Card>
              <div className="img-wrapper">
                <Card.Img
                  variant="top"
                  src={require("../../assets/img/svg/magnifiedopen.svg")}
                />
              </div>
              <Card.Body>
                <h3>
                  Keep your eyes <span>on the road</span>
                </h3>
                <Card.Text>
                  Through real-time analyzing and tracking of facial movements
                  and head position, NapDetect’s AI-powered safety solution
                  detects distractions and signs of drowsiness.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <div className="img-wrapper">
                <Card.Img
                  variant="top"
                  src={require("../../assets/img/svg/napalert.svg")}
                />
              </div>

              <Card.Body>
                <h3>
                  Nap<span>Alert</span>
                </h3>
                <Card.Text>
                  If the AI algorithms detect the person is distracted, drowsy,
                  or falling asleep, then NapDetect will trigger a distraction
                  or nap alert. The alert tones can be changed in Settings.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <div className="img-wrapper">
                <Card.Img
                  variant="top"
                  src={require("../../assets/img/svg/easytouse.svg")}
                />
              </div>
              <Card.Body>
                <h3>
                  Easy <span>to use</span>{" "}
                </h3>
                <Card.Text>
                  NapDetect can be downloaded on any smartphone. The setup is
                  simple. NapDetect can be used while driving at night and works
                  in parallel with many navigation apps on your device.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </div>

      <div className="screen-slider">
        <div className="heading-sec">
          <h3 className="title">
            <span>Why</span>
            <span className="font-f text-dark mr-0">
              NapDetect<sup className="tm">TM</sup>
            </span>
            ?
          </h3>
        </div>

        <div className="napdetect_videowrapper">
          <div className="napdetectvideo_section">
            <Player poster={require("../../assets/img/video1poster.png")}>
              <source src="https://napdetect.s3.amazonaws.com/video/Promo+V7.mp4" />
            </Player>
          </div>
        </div>
      </div>
      <div className="feature_section">
        <CardDeck>
          {CardWithIcon.map((item) => (
            <>
              <Card>
                <div className="img-wrapper">
                  <Card.Img variant="top" src={item.img} />
                </div>
                <Card.Body>
                  <h3>{item.heading}</h3>
                  <Card.Text>{item.content}</Card.Text>
                </Card.Body>
              </Card>
            </>
          ))}
        </CardDeck>
      </div>
      <div className="screen-slider">
        <div className="heading-sec">
          <h3 className="title">
            How Does{" "}
            <span className="font-f text-dark">
              NapDetect<sup className="tm">TM</sup>
            </span>
            <span>Work?</span>
          </h3>
        </div>

        <div className="napdetect_videowrapper">
          <div className="napdetectvideo_section">
            <Player poster={require("../../assets/img/video2poster.png")}>
              <source src="https://napdetect.s3.amazonaws.com/video/Tutorial+V6.mp4" />
            </Player>
          </div>
        </div>
      </div>
      <div className="downloadapp_section">
        <div className="page_container">
          <div className="row">
            <div className="col-md-6 mb-md-3">
              <div className="right_sec">
                <div className="inner-section">
                  {/* <h2 className="text-danger">Coming Soon!</h2> */}
                  <h3 className="heading-loto">
                    Download <span>NapDetect</span> Today!
                  </h3>
                  <p>Available today for both Android and iOS.</p>
                  <ul className="unstyle inline-img">
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/napdetect/id1497875807"
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/img/appstore.png")}
                          className="download-links"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.app.napdetect"
                        target="_blank"
                      >
                        <img
                          src={require("../../assets/img/googleplay.png")}
                          className="download-links"
                        />
                      </a>
                    </li>
                    {/* <h2 className="colorred">Coming soon...</h2> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="img-right_col">
                {/* <img src={require('../../assets/img/napdetec welcome.png')} /> */}
                <div className="screen-slide">
                  <div className=" widgets_section">
                    <div className="phn_screen">
                      <img
                        src={require("../../assets/img/slider/iPhone X blank.png")}
                      />
                    </div>
                    <PhoneScreenSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
