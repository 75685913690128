import React from "react";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <div class=" bodypadding ">
      <div class=" container fontSz privacypolicy">
        <div class="inner_wrap ">
          <h4 class="inner-container alignment">
            <u>BitAnimate, Inc.</u>
            <br />
          </h4>
          <h4 class="inner-container alignment2">
            {" "}
            <u>NapDetect</u>
            <br />
          </h4>
          <h4 class="inner-container alignment3">
            {" "}
            <u>Privacy Notice</u>
          </h4>

          <div classname="">
            <p class="heading">
              <b>
                1. <u>Overview</u>
              </b>
            </p>
            <p>
              BitAnimate, Inc. ("BitAnimate,” "we," "us," and "our") respects
              your privacy and is committed to protecting it through compliance
              with this Privacy Notice (“Privacy Notice”). This Privacy Notice
              describes the types of Information we may collect from you when
              you use the NapDetect mobile application provided herewith (the
              “Application”). It also describes our practices for collecting,
              using, maintaining, protecting, and disclosing that Information.
              This Privacy Notice does not apply to Information collected by us
              offline, through any other means or by any third party.
            </p>

            <p>
              Please read this Privacy Notice carefully to understand our
              policies and practices regarding your Information and how we will
              handle it. If you do not agree with our policies and practices, do
              not use the Application. By accessing or using the Application,
              you agree to this Privacy Notice.
            </p>

            <p>
              <i>
                <b>
                  BitAnimate may change this Privacy Notice at any time, at its
                  discretion.{" "}
                </b>
              </i>
              Your continued use of the Application after we make changes is
              deemed to be acceptance of those changes, so please check the
              Privacy Notice periodically for updates.
            </p>

            <p>
              This Privacy Notice is subject to and governed by the NapDetect
              Terms of Use (“Terms of Use”) available{" "}
              <Link target="_blank" to="/termsofuse">
                here.
              </Link>
            </p>

            <p class="heading">
              <b>
                2.{" "}
                <u>
                  {" "}
                  The Types of Information That BitAnimate Collects About You
                  and How BitAnimate Collects Information About You
                </u>
              </b>
            </p>
            <p>
              BitAnimate may collect two types of information from you when you
              use the Application: Personal Information and Non-Personal
              Information (collectively “Information”).
            </p>

            <p class="alignment4"></p>
            <ul>
              <li>
                “Personal Information” refers to data by which you may be
                personally identified, such as name, postal address, e-mail
                address, and telephone number.
              </li>
              <li>
                {" "}
                “Non-Personal Information” means data that is about you, but
                does not identify you specifically. This includes information
                such as location data and the date and time of logging into the
                Application.{" "}
              </li>
            </ul>
            <p></p>

            <p>We collect Information:</p>
            <p></p>
            <ul>
              <li> Directly from you when you provide it to us.</li>
              <li> Automatically as you interact with the Application.</li>
            </ul>
            <p></p>

            <p>
              <i> Information You Provide Directly to BitAnimate.</i> The
              Information that you provide us directly through the Application
              may include:
            </p>
            <p></p>
            <ul>
              <li>
                Information that you provide to BitAnimate by filling out forms,
                setting up a personal profile, or contacting us for support. We
                may also ask you for Information when you report a problem with
                the Application, and it may be necessary for you to provide us
                with Information so we can communicate with you about an inquiry
                you have.
              </li>
              <li>
                {" "}
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete.
              </li>
            </ul>
            <p></p>

            <p>
              <i>Information Automatically Collected from You. </i>The
              Information that BitAnimate may automatically collect and store
              about you when you use the Application may include:
            </p>
            <p></p>
            <ul>
              <li>
                The location of your mobile device when you open the
                Application. The location of your mobile device when you open
                the Application. Although users can optout of sharing their
                precise location data that would otherwise be collected using
                the location features of your mobile device, NapDetect will
                still make inferences regarding user location based on the IP
                address of a user’s device;
              </li>
              <li> The date and time you used the Application;</li>
              <li>The length of time you used the Application;</li>
              <li>
                Your image, including an image of your face when you use the
                Application;
              </li>
              <li>
                {" "}
                Transactional information if you pay for the Application or
                advertise with us; and
              </li>

              <li>
                Cookies, which are small text files that a web server (such as
                BitAnimate‘s or its chosen web hosting company server) places on
                a user's computer. When accessing a cookie, BitAnimate’s server
                reads a number associated with a browser, but is unable to
                determine any data about a user. With that number, our web
                server can tailor its content to the needs of the specific
                browser, and we can optimize the Application for your use.
              </li>
            </ul>
            <p></p>

            <p>
              We may also collect general information about the equipment you
              use to access and utilize the Application, your operating system
              and browsing type, the services that you use, times and dates of
              your interactions with the Application, details of your
              transactions through the Application, and mobile software event
              data.
            </p>

            <p>
              <i>Google Analytics Advertising Features.</i>We also may have the
              following Google Analytics Advertising Features Implemented:
              Demographics and Interest reporting, Remarketing, GDN Impression
              Reporting and Double Click Campaign Manager. These features
              collect data using Google advertising cookies and anonymous
              identifiers. BitAnimate and third-party vendors use these
              first-party cookies to access the success of online marketing
              campaigns, inform future campaigns and improve usability on the
              Site. To learn how to opt-out of the Google Analytics Advertising
              Features we use, please see Google Analytics’ currently available
              opt-outs for the web.
            </p>

            <p class="heading">
              <b>
                3.{" "}
                <u>
                  How BitAnimate Uses Information It Collects About You and the
                  Purpose for the Collection and Use
                </u>
              </b>
            </p>
            <p>
              We use Information that we collect about you or that you provide
              to us, including any Personal Information, for the following
              purposes:{" "}
            </p>
            <ul>
              <li>
                To present our Application and the Application Contents (as
                defined in the Terms of Use) to you.
              </li>
              <li>
                To provide you with data or services that you request from us.
                For example, when you provide your email address to contact us,
                we are able to use your email address to respond to your comment
                or question personally.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Application or any products
                or services we offer or provide though it.
              </li>
              <li>To develop and improve the Application.</li>
              <li>
                To communicate with you regarding our Application and services,
                including for marketing purposes.
              </li>
              <li>
                To provide and improve our advertising services and ad
                targeting, including through the use of your location
                information.
              </li>
              <li>
                In any other way we may describe when you provide the
                Information.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p>
              BitAnimate will retain your Information as long as BitAnimate
              believes it is appropriate for the purposes of this Privacy
              Notice.
            </p>

            <p class="heading">
              <b>
                4. <u>How BitAnimate Protects Your Information </u>
              </b>
            </p>
            <p>
              We have implemented measures designed to protect your Information
              from accidental loss and from unauthorized access, use,
              alteration, and disclosure. Unfortunately, the internet is not
              completely secure. Although we are working to protect your
              Information, we cannot guarantee the security of your Information
              either in transmission or when stored. We are not responsible for
              circumvention of any privacy settings or security measures
              contained in the Application.
            </p>

            <p class="heading">
              <b>
                5. <u>When BitAnimate Shares Your Information</u>
              </b>
            </p>
            <p>
              We may use and disclose Non-Personal Information about our users
              without restriction. We may disclose Personal Information that we
              collect or you provide as described in this Privacy Notice:
            </p>
            <li>
              {" "}
              To our subsidiaries and affiliates, including third party payment
              facilitators.
            </li>
            <li>
              To contractors, service providers, and other third parties we use
              to support our business, as described further below.
            </li>
            <li>
              To a buyer or other successor (potential or actual) in connection
              with a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of
              BitAnimate’s assets, including any related due diligence.
            </li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>
              {" "}
              For any other purpose disclosed by us when you provide the
              Information.
            </li>
            <li>With your consent.</li>
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>To enforce or apply our Terms of Use.</li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of BitAnimate or others.
            </li>

            <p>
              {" "}
              Except as described above, BitAnimate does not sell any of your
              information.
            </p>

            <p class="heading">
              <b>
                6. <u>Third Parties Providing Services On Our Behalf</u>
              </b>
            </p>
            <p>
              We use third parties to host the Application, design and operate
              the Application features, and perform administrative, analytics,
              payment processing, and communications. Services these parties
              provide include enabling BitAnimate to send you email newsletters
              and push notifications, which may contain personalized content or
              information specific to your use of the Application, and providing
              information to BitAnimate about the use of the Application and
              viewing of our content. We may disclose your Information to these
              companies so they can carry out these services. These companies
              are bound by contractual obligations to keep Information
              confidential.
            </p>

            <p class="heading">
              <b>
                7. <u> Information From Children Under 13</u>
              </b>
            </p>
            <p>
              BitAnimate complies with the requirements of the Children’s Online
              Privacy Protection Act and related regulations. The Application is
              not directed at children, and BitAnimate does not knowingly
              collect any Information from children under 13 years of age. If
              BitAnimate learns that a child under 13 years of age has provided
              Information, BitAnimate will delete this Information from our
              databases. If you are under 13, do not use this Application or
              provide any Information about yourself to BitAnimate, including
              your name, address, telephone number or email address. If you
              become aware that Information of a child under 13 years of age has
              been provided, please contact us <a target="_blnk" href="https://bitanimate.com/contact">
                 here
              </a>
              
              . Please see the Terms of Use for further guidance.
            </p>
            <p class="heading">
              <b>
                8. <u>Links to Other Websites and Services</u>
              </b>
            </p>
            <p>
              We are not responsible for the practices employed by websites or
              services linked to or from the Application, including the
              information or content contained therein. Our Privacy Notice does
              not apply to third-party websites or services.
            </p>
            <p class="heading">
              <b>
                9. <u>Do Not Track</u>
              </b>
            </p>
            <p>
              NapDetect does not have a mechanism in place for responding to
              browser “do not track” signals or other similar mechanisms used to
              limit collection of information for use in online advertising.
            </p>
            <p class="heading">
              <b>
                10. <u>Your Choices About Information</u>
              </b>
            </p>
            <p>
              If you choose not to provide Information, you may be unable to
              access or use all portions of the Application. If you would like
              to review, correct or delete your information, or register a
              complaint, please contact us to submit a request{" "}
              <a target="_blnk" href="https://bitanimate.com/contact">
                here
              </a>
              . You can also delete your account with NapDetect at any time. We
              send promotional and marketing communications only with your
              consent. If you no longer wish to receive these or other
              communications, please contact us. You can also stop receiving
              newsletter email communications from us by clicking on the
              “unsubscribe link” provided in such communications. Please report
              your concerns about collection or use of Personal Information
              available on the Application by contacting us{" "}
              <a target="_blnk" href="https://bitanimate.com/contact">
                here
              </a>
              .
            </p>

            <p class="heading">
              <b>
                11. <u>Residents of the European Economic Area (“EEA”)</u>
              </b>
            </p>
            <p>
              If you are located in the EEA, you may have certain rights under
              European law with respect to your personal data that are described
              below. The Application is not intended for users located in the
              EEA who are under the age of 16. If you are located in the{" "}
              <b>
                EEA and are under the age of 16, do not use this Application.
              </b>
            </p>
            <p>
              <u> Controller of Personal Information.</u> BitAnimate is the
              Controller of your Personal Information. BitAnimate’s primary
              place of business is 4800 Meadows Rd., Suite 300, Lake Oswego,
              Oregon 97035.
            </p>
            <p>
              {" "}
              <u> Processing.&nbsp;</u> BitAnimate may process your Personal
              Information on one or more of the following legal bases:
            </p>

            <ul>
              <li>
                {" "}
                as necessary to enter into a contract with you or a legal entity
                you represent, to perform our contractual obligations, to
                provide services, to respond to requests from you, or to provide
                customer support;
              </li>
              <li>
                {" "}
                where we have a legitimate interest, as described in this
                Privacy Notice (see “How BitAnimate Uses Information It Collects
                About You and the Purposes for Collection and Use”);
              </li>
              <li>
                {" "}
                as necessary to comply with relevant law and legal obligations,
                including to respond to lawful requests and orders; or
              </li>
              <li>with your consent.</li>
            </ul>

            <p>
              {" "}
              <u>Your Rights.</u> Subject to applicable law, you may have the
              right to:
            </p>
            <ul>
              <li>
                ask whether we hold personal information about you and request
                copies of such Personal Information and information about how it
                is processed;
              </li>
              <li>
                request that inaccurate Personal Information is corrected;
              </li>
              <li>
                request deletion of Personal Information that is no longer
                necessary for the purposes underlying the processing, processed
                based on withdrawn consent, or processed in non-compliance with
                applicable legal requirements;
              </li>
              <li>
                request us to restrict the processing of Personal Information
                where the processing is inappropriate;
              </li>
              <li>object to the processing of Personal Information;</li>
              <li>
                request portability of Personal Information that you have
                provided to us (which does not include information derived from
                the collected information), where the processing of such
                Personal Information is based on consent or a contract with you
                and is carried out by automated means; and&nbsp;
              </li>
              <li>
                lodge a complaint with the appropriate data protection
                authorities.
              </li>
            </ul>

            <p>
              You can exercise your rights of access, rectification, erasure,
              restriction, objection, and data portability by contacting us at
              the email address in section 13 of this Privacy Notice.
            </p>
            <p>
              {" "}
              When you consent to our processing your Personal Information for a
              specified purpose, you may withdraw your consent at any time, and
              we will stop any further processing of your data for that purpose.
            </p>
            <p>
              {" "}
              The categories of Personal Information that BitAnimate collects
              are identified in section 2, “The Types of Information That
              BitAnimate Collects About You and How BitAnimate Collects
              Information About You.” Additional information about how long
              BitAnimate will retain your Information and how it shares your
              Information is found in section 3, “How BitAnimate Uses
              Information It Collects About You and the Purpose for the
              Collection and Use,” and section 5, “When BitAnimate Shares Your
              Information” above.
            </p>

            <p class="heading">
              <b>
                12. <u>Your California Privacy Rights</u>
              </b>
            </p>
            <p>
              California's "Shine the Light" law (Civil Code Section § 1798.83)
              permits users of the Application that are California residents to
              request certain information regarding our disclosure of personal
              information to third parties for their direct marketing purposes.
              To make such a request, please contact us{" "}
              <a target="_blnk" href="https://bitanimate.com/contact">
                here
              </a>{" "}
              or at the physical address included below.
            </p>
            <p class="heading">
              <b>
                13. <u>How to Contact Us About This Privacy Notice</u>
              </b>
            </p>
            <p>
              To ask questions or comment about this Privacy Notice and our
              privacy practices, please contact us{" "}
              <a target="_blnk" href="https://bitanimate.com/contact">
                here
              </a>{" "}
              or by mail at:{" "}
            </p>
            <p>
              {" "}
              BitAnimate, Inc.
              <br />
              4800 Meadows Road, Suite 300 <br />
              Lake Oswego, Oregon 97035
            </p>
            <br />
            <p>EFFECTIVE DATE: March 12, 2020.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
