import React from 'react'
import { SocialIcon, SocialIconInside, SocialIconslider3 } from './SocialIcon'
import { Carousel } from 'react-bootstrap'

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const BannerSliderV2 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        fade: false,
        arrows: false,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        focusOnSelect: true,
        pauseOnFocus: true
    }
    return (
        <Slider {...settings} className="homeslider">
            <div className="slidebox"  >
                <div className="slidebox-inner">
                    <div className="banner-img video"  >
                        <Carousel.Caption className="custom_caption p video_slide">
                            <div className="carousel_container">
                                <div className="content first">
                                    <div className="banner-slider left">
                                        <SocialIcon />
                                        <div className="banner-slider center text-center">
                                            {/* <img src={require('../../assets/img/iPhone X screen.png')} /> */}
                                            <div className="video_section">
                                                <video height="650" id="vid" loop autoPlay muted playsInline>
                                                    <source src="https://napdetect.s3.amazonaws.com/video/bannervideo.mp4" type="video/mp4" />
                                                    <source src="https://napdetect.s3.amazonaws.com/video/bannervideo.mp4" type="video/ogg" />
                                                </video>
                                                {/* <img src={require('../img/nap.gif')} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </div>
                </div>
            </div>
            <div className="slidebox"  >
                <div className="slidebox-inner">
                    <div className="banner-img " style={{ backgroundImage: `url(${require('../../assets/img/banner.png')})` }} >
                        <Carousel.Caption className="custom_caption second_slide">
                            <div className="carousel_container">
                                <div className="row">
                                    <div className="col-md-6 content">
                                        <div className="banner-slider left">
                                            <SocialIconInside />
                                        </div>
                                    </div>
                                    <div className="col-md-6 img">
                                        <div className="banner-right-sec">
                                            <div className="slide1"><img src={require('../../assets/img/slider2v3.png')} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </div>
                </div>
            </div>
            <div className="slidebox" >
                <div className="slidebox-inner middle_screen mobile_content">
                    <div className="banner-img lst" style={{ backgroundImage: `url(${require('../../assets/img/bannerbg.jpg')})` }} >
                        <Carousel.Caption className="custom_caption">
                            <div className="carousel_container">
                                <div className="row">
                                    <div className="col-md-8 content">
                                        <div className="banner-slider left">
                                            <SocialIconslider3 />
                                        </div>
                                    </div>
                                    <div className="col-md-4 img">
                                        <div className="banner-right-last d-none"><img src={require('../../assets/img/slider3.png')} /></div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export { BannerSliderV2 };