import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';
import {NavLink} from 'react-router-dom'

const Header = () => {
    
        return (
            <div className="header-container-wrapper">
                    
                        <Navbar expand="lg">
                        <div className="page_container">
                            <Navbar.Brand target="_blank" href="https://bitanimate.com/"><img src={require('../../assets/img/logo.final.jpg')} /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                <li className="nav-item">
                                    <NavLink to="//bitanimate.com/" target="_blank" className="active nav-link">Napdetect</NavLink>                            </li>
                                <li className="nav-item">
                                <NavLink to="//bitanimate.com/mapping-navigation" className="nav-link" target="_blank">Mapping &  Navigation</NavLink>                            </li>
                                <li className="nav-item">
                                <NavLink to="//bitanimate.com/video-conversion" className="nav-link" target="_blank"><span className="sup1">2</span>D to <span className="sup2">3</span>D CONVERSION</NavLink>                            </li>
                                <li className="nav-item">
                                <NavLink to="//bitanimate.com/careers" className="nav-link" target="_blank">career</NavLink>                            </li>
                                <li className="nav-item">
                                <NavLink to="//bitanimate.com/contact" className="nav-link" target="_blank">contact</NavLink>                            </li>
                                </Nav>
                            </Navbar.Collapse>
                            </div>
                        </Navbar>
                        
                    
            </div>
        )
}

export default Header;
