import React from 'react'

const Page404 = () => {
    return(
        <div className="page_container">
            <div class="main errorpage_wrapper">
            <div class="error">
                <div id="outline">
                    <div id="errorboxoutline">
                        <div class="error-code"><span class="first">4</span><span class="">0</span><span class="last">4</span>					</div>
                        <div class="wrap-text">
                            <div class="error-message"><h2>Article not found</h2></div>
                            <div id="errorboxbody">
                                <p>Please try one of the following pages:</p>
                            </div>
                            <a class="button-home" href="/" title="Go to the Home Page">Home Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Page404;