import React, { Component } from "react";

const Footer = () => {
  // const privacy = () => {
  //     window.open('https://devapi.napdetact.tk/napDetect/privacyandpolicy','popup','width=1000,height=600'); return false
  // }
  // const terms = () => {
  //     window.open('https://devapi.napdetact.tk/napDetect/termsandpolicy','popup','width=1000,height=600'); return false
  // }
  return (
    <div>
      <footer className="footer_wrapper">
        <p>
          BitAnimate products and services are covered by issued and pending
          U.S. and foreign patents.
        </p>
        <ul className="unstyle inline-img">
          <li>
            <a href="http://bit.ly/napdetect-instagram" target="_blank">
              <i>
                <img src={require("../../assets/img/insta.png")} />
              </i>
            </a>
          </li>
          <li>
            <a href="http://bit.ly/napdetect-twitter" target="_blank">
              {" "}
              <i>
                <img src={require("../../assets/img/twitter.png")} />
              </i>
            </a>
          </li>
          <li>
            <a href="http://bit.ly/napdetect-facebook" target="_blank">
              <i>
                <img src={require("../../assets/img/facebook.png")} />
              </i>
            </a>
          </li>
        </ul>
        <small className="font_12">
          © 2021 BitAnimate, Inc. All Rights Reserved.{" "}
        </small>
        <p className="mb-0 font-b">
          <small>
            <a href="/privacypolicy" target="_blank">
              {" "}
              Privacy Policy
            </a>{" "}
            |
            <a target="_blank" href="/termsofuse">
              {" "}
              Terms of use
            </a>
          </small>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
