import React from "react";

const SocialIcon = () => {
  return (
    <ul className="unstyle">
      <li>
        <a href="http://bit.ly/napdetect-facebook" target="_blank">
          <i>
            <img src={require("../../assets/img/facebook.png")} />
          </i>
        </a>
      </li>
      <li>
        <a href="http://bit.ly/napdetect-twitter" target="_blank">
          {" "}
          <i>
            <img src={require("../../assets/img/twitter.png")} />
          </i>
        </a>
      </li>
      <li>
        <a href="http://bit.ly/napdetect-instagram" target="_blank">
          <i>
            <img src={require("../../assets/img/insta.png")} />
          </i>
        </a>{" "}
      </li>
    </ul>
  );
};
const SocialIconInside = () => {
  return (
    <ul className="unstyle">
      <li>
        <a href="http://bit.ly/napdetect-facebook" target="_blank">
          <i>
            <img src={require("../../assets/img/facebook.png")} />
          </i>
        </a>
        <h1>
          NapDetect<sup>TM</sup>{" "}
        </h1>
      </li>
      <li>
        <a href="http://bit.ly/napdetect-twitter" target="_blank">
          {" "}
          <i>
            <img src={require("../../assets/img/twitter.png")} />
          </i>
        </a>
        <h2>Must Have In-Vehicle Safety App</h2>
      </li>
      <li>
        <a href="http://bit.ly/napdetect-instagram" target="_blank">
          <i>
            <img src={require("../../assets/img/insta.png")} />
          </i>
        </a>
        <ul className="unstyle inline-img">
          <li>
            <a
              href="https://apps.apple.com/us/app/napdetect/id1497875807"
              target="_blank"
            >
              <img src={require("../../assets/img/appstore.png")} />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.napdetect"
              target="_blank"
            >
              <img src={require("../../assets/img/googleplay.png")} />
            </a>
          </li>
        </ul>
        {/* <h2 className="colorred">Coming soon...</h2> */}
      </li>
    </ul>
  );
};
const SocialIconslider3 = () => {
  return (
    <ul className="unstyle">
      {/* <li><h2 className="text-light pl-0 pr-3 mb-0">Coming Soon!</h2></li> */}
      <li>
        <a href="http://bit.ly/napdetect-facebook" target="_blank">
          <i>
            <img src={require("../../assets/img/facebook.png")} />
          </i>
        </a>
        <h1>
          NapDetect<sup>TM</sup>
        </h1>
      </li>
      <li className="third_slider">
        <a href="http://bit.ly/napdetect-twitter" target="_blank">
          {" "}
          <i>
            <img src={require("../../assets/img/twitter.png")} />
          </i>
        </a>
        <h2>Distraction & Drowsiness Alerts</h2>
      </li>

      <li>
        <a href="http://bit.ly/napdetect-instagram" target="_blank">
          <i>
            <img src={require("../../assets/img/insta.png")} />
          </i>
        </a>
        <ul className="unstyle inline-img">
          <li>
            <a
              href="https://apps.apple.com/us/app/napdetect/id1497875807"
              target="_blank"
            >
              {" "}
              <img src={require("../../assets/img/appstore.png")} />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.napdetect"
              target="_blank"
            >
              <img src={require("../../assets/img/googleplay.png")} />{" "}
            </a>
          </li>
        </ul>
        {/* <h2 className="colorred">Coming soon...</h2> */}
      </li>
    </ul>
  );
};

export { SocialIcon, SocialIconInside, SocialIconslider3 };
